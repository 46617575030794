import React, { useState } from 'react'
import { Button, Modal, Form, Row, Col } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { Text } from '../../../../Themed'
import { gql, useMutation, useQuery } from '@apollo/client'
import { NEUTRAL_2, PRIMARY_1 } from '../../../../../constants/colors'

const MIN_SCORE = 0
const MAX_SCORE = 10

const CORE_SCORE_FIELDS = gql`
  fragment CoreScoreFields on Score {
    id
    site
    subscores {
      interconnect {
        value
        description
      }
      landowner {
        value
        description
      }
      permitting {
        value
        description
      }
      offtake {
        value
        description
      }
      energyCommunity {
        value
        description
      }
      buildability {
        value
        description
      }
    }
  }
`

const CREATE_SCORE = gql`
  ${CORE_SCORE_FIELDS}
  mutation CREATE_SCORE($score: ScoreInput!) {
    createScore(score: $score) {
      ...CoreScoreFields
    }
  }
`

const UPDATE_SCORE = gql`
  ${CORE_SCORE_FIELDS}
  mutation UPDATE_SCORE($id: ID!, $score: ScoreInput!) {
    updateScore(id: $id, score: $score) {
      ...CoreScoreFields
    }
  }
`

const FIND_SCORES = gql`
  ${CORE_SCORE_FIELDS}
  query FIND_SCORES($filter: JSON!) {
    scores: findScores(filter: $filter) {
      ...CoreScoreFields
    }
  }
`

const scoreSchema = Yup.object({
  interconnect: Yup.object({
    value: Yup.number()
      .required('This is required! Enter 5 if unsure')
      .min(MIN_SCORE, `Should be greater than or equals ${MIN_SCORE}`)
      .max(MAX_SCORE, `Should be less than or equals ${MAX_SCORE}`)
      .typeError('This is required! Enter 5 if unsure'),
    description: Yup.string().trim().nullable(),
  }),
  landowner: Yup.object({
    value: Yup.number()
      .required('This is required! Enter 5 if unsure.')
      .min(MIN_SCORE, `Should be greater than or equals ${MIN_SCORE}`)
      .max(MAX_SCORE, `Should be less than or equals ${MAX_SCORE}`)
      .typeError('This is required! Enter 5 if unsure'),
    description: Yup.string().trim().nullable(),
  }),
  permitting: Yup.object({
    value: Yup.number()
      .required('This is required! Enter 5 if unsure.')
      .min(MIN_SCORE, `Should be greater than or equals ${MIN_SCORE}`)
      .max(MAX_SCORE, `Should be less than or equals ${MAX_SCORE}`)
      .typeError('This is required! Enter 5 if unsure'),
    description: Yup.string().trim().nullable(),
  }),
  offtake: Yup.object({
    value: Yup.number()
      .required('This is required! Enter 5 if unsure.')
      .min(MIN_SCORE, `Should be greater than or equals ${MIN_SCORE}`)
      .max(MAX_SCORE, `Should be less than or equals ${MAX_SCORE}`)
      .typeError('This is required! Enter 5 if unsure'),
    description: Yup.string().trim().nullable(),
  }),
  energyCommunity: Yup.object({
    value: Yup.number()
      .required('This is required! Enter 5 if unsure.')
      .min(MIN_SCORE, `Should be greater than or equals ${MIN_SCORE}`)
      .max(MAX_SCORE, `Should be less than or equals ${MAX_SCORE}`)
      .typeError('This is required! Enter 5 if unsure'),
    description: Yup.string().trim().nullable(),
  }),
  buildability: Yup.object({
    value: Yup.number()
      .required('This is required! Enter 5 if unsure.')
      .min(MIN_SCORE, `Should be greater than or equals ${MIN_SCORE}`)
      .max(MAX_SCORE, `Should be less than or equals ${MAX_SCORE}`)
      .typeError('This is required! Enter 5 if unsure'),
    description: Yup.string().trim().nullable(),
  }),
})

function ScoreForm({ values = {}, onScoreSubmit, loading }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      ...values,
    },
    resolver: yupResolver(scoreSchema),
  })

  return (
    <Form noValidate onSubmit={handleSubmit(onScoreSubmit)}>
      <Row className="m-0">
        <Col
          className="border rounded p-2 m-1"
          style={{ background: `${NEUTRAL_2}20` }}
        >
          <Text size={12} weight="bold" color={PRIMARY_1}>
            Interconnect
          </Text>
          <hr className="m-0" />
          <Form.Group className="m-0" controlId="interconnect.value">
            <Text size={12} color={PRIMARY_1}>
              Score*
            </Text>
            <Form.Control
              {...register('interconnect.value')}
              type="number"
              style={{ fontSize: 12 }}
              size="sm"
              isInvalid={!!errors.interconnect?.value}
            />
            <Form.Control.Feedback
              className="m-0"
              type="invalid"
              style={{ fontSize: 10 }}
            >
              {errors.interconnect?.value?.message}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="m-0" controlId="interconnect.description">
            <Text size={12} color={PRIMARY_1}>
              Description
            </Text>
            <Form.Control
              {...register('interconnect.description')}
              as="textarea"
              style={{ fontSize: 12 }}
              size="sm"
              rows={2}
              isInvalid={!!errors.interconnect?.description}
            />
            <Form.Control.Feedback
              className="m-0"
              type="invalid"
              style={{ fontSize: 10 }}
            >
              {errors.interconnect?.description?.message}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col
          className="border rounded p-2 m-1"
          style={{ background: `${NEUTRAL_2}20` }}
        >
          <Text size={12} weight="bold" color={PRIMARY_1}>
            Landowner Participation
          </Text>
          <hr className="m-0" />
          <Form.Group className="m-0" controlId="landowner.value">
            <Text size={12} color={PRIMARY_1}>
              Score*
            </Text>
            <Form.Control
              {...register('landowner.value')}
              type="number"
              style={{ fontSize: 12 }}
              size="sm"
              isInvalid={!!errors.landowner?.value}
            />
            <Form.Control.Feedback
              className="m-0"
              type="invalid"
              style={{ fontSize: 10 }}
            >
              {errors.landowner?.value?.message}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="m-0" controlId="landowner.description">
            <Text size={12} color={PRIMARY_1}>
              Description
            </Text>
            <Form.Control
              {...register('landowner.description')}
              as="textarea"
              size="sm"
              style={{ fontSize: 12 }}
              rows={2}
              isInvalid={!!errors.landowner?.description}
            />
            <Form.Control.Feedback
              className="m-0"
              type="invalid"
              style={{ fontSize: 10 }}
            >
              {errors.landowner?.description?.message}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>
      <Row className="m-0">
        <Col
          className="border rounded p-2 m-1"
          style={{ background: `${NEUTRAL_2}20` }}
        >
          <Text size={12} weight="bold" color={PRIMARY_1}>
            Permitting
          </Text>
          <hr className="m-0" />
          <Form.Group className="m-0" controlId="permitting.value">
            <Text size={12} color={PRIMARY_1}>
              Score*
            </Text>
            <Form.Control
              {...register('permitting.value')}
              type="number"
              style={{ fontSize: 12 }}
              size="sm"
              isInvalid={!!errors.permitting?.value}
            />
            <Form.Control.Feedback
              className="m-0"
              type="invalid"
              style={{ fontSize: 10 }}
            >
              {errors.permitting?.value?.message}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="m-0" controlId="permitting.description">
            <Text size={12} color={PRIMARY_1}>
              Description
            </Text>
            <Form.Control
              {...register('permitting.description')}
              as="textarea"
              rows={2}
              style={{ fontSize: 12 }}
              size="sm"
              isInvalid={!!errors.permitting?.description}
            />
            <Form.Control.Feedback
              className="m-0"
              type="invalid"
              style={{ fontSize: 10 }}
            >
              {errors.permitting?.description?.message}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col
          className="border rounded p-2 m-1"
          style={{ background: `${NEUTRAL_2}20` }}
        >
          <Text size={12} weight="bold" color={PRIMARY_1}>
            Offtake
          </Text>
          <hr className="m-0" />
          <Form.Group className="m-0" controlId="offtake.value">
            <Text size={12} color={PRIMARY_1}>
              Score*
            </Text>
            <Form.Control
              {...register('offtake.value')}
              type="number"
              style={{ fontSize: 12 }}
              size="sm"
              isInvalid={!!errors.offtake?.value}
            />
            <Form.Control.Feedback
              className="m-0"
              type="invalid"
              style={{ fontSize: 10 }}
            >
              {errors.offtake?.value?.message}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="m-0" controlId="offtake.description">
            <Text size={12} color={PRIMARY_1}>
              Description
            </Text>
            <Form.Control
              {...register('offtake.description')}
              as="textarea"
              size="sm"
              style={{ fontSize: 12 }}
              rows={2}
              isInvalid={!!errors.offtake?.description}
            />
            <Form.Control.Feedback
              className="m-0"
              type="invalid"
              style={{ fontSize: 10 }}
            >
              {errors.offtake?.description?.message}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>
      <Row className="m-0">
        <Col
          className="border rounded p-2 m-1"
          style={{ background: `${NEUTRAL_2}20` }}
        >
          <Text size={12} weight="bold" color={PRIMARY_1}>
            Energy Community Zone
          </Text>
          <hr className="m-0" />
          <Form.Group className="m-0" controlId="energyCommunity.value">
            <Text size={12} color={PRIMARY_1}>
              Score*
            </Text>
            <Form.Control
              {...register('energyCommunity.value')}
              size="sm"
              type="number"
              style={{ fontSize: 12 }}
              isInvalid={!!errors.energyCommunity?.value}
            />
            <Form.Control.Feedback
              className="m-0"
              type="invalid"
              style={{ fontSize: 10 }}
            >
              {errors.energyCommunity?.value?.message}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="m-0" controlId="energyCommunity.description">
            <Text size={12} color={PRIMARY_1}>
              Description
            </Text>
            <Form.Control
              {...register('energyCommunity.description')}
              as="textarea"
              size="sm"
              style={{ fontSize: 12 }}
              rows={2}
              isInvalid={!!errors.energyCommunity?.description}
            />
            <Form.Control.Feedback
              className="m-0"
              type="invalid"
              style={{ fontSize: 10 }}
            >
              {errors.energyCommunity?.description?.message}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col
          className="border rounded p-2 m-1"
          style={{ background: `${NEUTRAL_2}20` }}
        >
          <Text size={12} weight="bold" color={PRIMARY_1}>
            Buildability
          </Text>
          <hr className="m-0" />
          <Form.Group className="m-0" controlId="buildability.value">
            <Text size={12} color={PRIMARY_1}>
              Score*
            </Text>
            <Form.Control
              {...register('buildability.value')}
              size="sm"
              style={{ fontSize: 12 }}
              type="number"
              isInvalid={!!errors.buildability?.value}
            />
            <Form.Control.Feedback
              className="m-0"
              type="invalid"
              style={{ fontSize: 10 }}
            >
              {errors.buildability?.value?.message}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="m-0" controlId="buildability.description">
            <Text size={12} color={PRIMARY_1}>
              Description
            </Text>
            <Form.Control
              {...register('buildability.description')}
              as="textarea"
              style={{ fontSize: 12 }}
              size="sm"
              rows={2}
              isInvalid={!!errors.buildability?.description}
            />
            <Form.Control.Feedback
              className="m-0"
              type="invalid"
              style={{ fontSize: 10 }}
            >
              {errors.buildability?.description?.message}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>
      <div className="d-flex p-1 justify-content-end w-100">
        <Button
          size="sm"
          className="btn-mid"
          variant="primary"
          type="submit"
          disabled={loading}
        >
          Save Changes
        </Button>
      </div>
    </Form>
  )
}

function UpdateScore({ site, score, onCompleted }) {
  const [updateScore, { loading }] = useMutation(UPDATE_SCORE)

  const handleScoreSubmit = scores => {
    const readinessScore = {
      site,
      subscores: scores,
    }

    updateScore({
      variables: { score: readinessScore, id: score.id },
      onCompleted,
    })
  }

  return (
    <React.Fragment>
      <ScoreForm
        onScoreSubmit={handleScoreSubmit}
        loading={loading}
        values={score.subscores}
      />
    </React.Fragment>
  )
}

function CreateScore({ site, onCompleted }) {
  const [createScore, { loading }] = useMutation(CREATE_SCORE)

  const handleScoreSubmit = scores => {
    const readinessScore = {
      site,
      subscores: scores,
    }

    createScore({
      variables: { score: readinessScore },
      onCompleted,
    })
  }

  return (
    <React.Fragment>
      <ScoreForm onScoreSubmit={handleScoreSubmit} loading={loading} />
    </React.Fragment>
  )
}

function ReadinessScore({ site, onCompleted }) {
  const { loading, error, data } = useQuery(FIND_SCORES, {
    variables: { filter: { site } },
  })

  if (loading) return <p>loading...</p>
  if (error) return <p>error...</p>

  const { scores } = data

  return (
    <React.Fragment>
      {scores.length > 0 ? (
        <UpdateScore site={site} score={scores[0]} onCompleted={onCompleted} />
      ) : (
        <CreateScore site={site} onCompleted={onCompleted} />
      )}
    </React.Fragment>
  )
}

function Score({ site, siteName }) {
  const [show, setShow] = useState(false)

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  return (
    <React.Fragment>
      <Button
        variant="outline-primary"
        className="ml-2"
        size="sm"
        onClick={handleShow}
      >
        Readiness
      </Button>
      <Modal
        size="lg"
        show={show}
        onHide={handleClose}
        centered
        backdrop="static"
      >
        <Modal.Header closeButton>
          <div className="d-flex align-items-center justify-content-center">
            <Text size={16} weight="bold" color={PRIMARY_1} className="mr-2">
              Readiness Score | {siteName}
            </Text>
          </div>
        </Modal.Header>
        <Modal.Body>
          <ReadinessScore site={site} onCompleted={handleClose} />
        </Modal.Body>
      </Modal>
    </React.Fragment>
  )
}

export default Score
